import React from "react";
import styled from "@emotion/styled";
import Flex from "../UI/flex";
import Field from "../UI/Field";
import Btn from "../UI/button";
import { Formik } from 'formik';
import * as Yup from 'yup';


const Container = styled(Flex)`
    position:relative;
`;


const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .email('Correo no válido.')
        .required('Debe ingresar un correo.'),
});


function SenEmail({sendEmail,loading,disabled}) {

    return (

        <Formik
            initialValues={{
                email: '',
            }}
            validationSchema={SignupSchema}
            onSubmit={(values,{resetForm}) => {
                // same shape as initial values
                sendEmail(values.email)
                resetForm({})
            }}
        >
            {({errors, touched, setFieldValue, values,handleSubmit}) => (

                <form onSubmit={(event)=>{
                    event.preventDefault()
                    handleSubmit()
                }}>
                    <Container alg={"flex-start"}>
                        <Field icon={3} flex={"1 0 80px"} error={(errors.email && touched.email) && errors.email}>
                            <input
                                type="text"
                                placeholder={"Tu correo electrónico"}
                                value={values.email}
                                disabled={loading || disabled}
                                onChange={(e) => setFieldValue('email', e.target.value)}
                            />
                        </Field>
                        <Flex className={"pl-2"} flex={"0 0 180px"}>
                            <Btn
                                typeBtn={"line"}
                                loading={loading}
                                disabled={loading || disabled}
                            >
                                Enviar
                            </Btn>
                        </Flex>
                    </Container>
                </form>

            )}
        </Formik>
    )
}

export default React.memo(SenEmail);
