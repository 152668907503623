import React, {useEffect}from "react";
import styled from "@emotion/styled";
import bg from "./../../img/bg.png";
import Flex from "./../UI/flex";
import Formulario from "./Formulario";
import Banner from "./banner";

const Container = styled(Flex)`
    position:relative;
    min-height:100%;
    background: white url(${bg}) no-repeat center;
    background-size:cover;
    width:100%;
    padding-top:80px;
    
    @media all and (max-width:767px){
        background: white no-repeat center;
        justify-content:flex-start;
        padding-top:0;
    }
`;

function Home() {



    useEffect(() => {

       
        localStorage.clear();
        
       
    }, [  ])

    return (
        <Container direction={"column"}>
            <Flex className={"wc col-xl-10 px-0"}>
                <Formulario flex={ "1 0 40%"} className={"p-md-3 p-xl-0"}/>
                <Banner/>
            </Flex>
        </Container>
    )
}

export default React.memo(Home);
