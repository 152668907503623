import React, { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Btn from "./../../UI/button";
import mediosP from "./../../../img/mediosPagos.png";
import Flex from "./../../UI/flex";
import Field from "./../../UI/Field";
import Icon from "./../../UI/icon";
import {Formik} from "formik";
import * as Yup from 'yup';
import Cleave from "cleave.js/react";
import {SetDataHome, Setglobal} from "../../../store/actions/actionsCreators";
import {connect} from "react-redux";
import { amonRequestHandler, getUrlParamValue, setNewTagManager } from "./../../utils/index"
import { withRouter } from "react-router-dom";

const FormInicio = Yup.object().shape({
    numero: Yup.string()
        .min(14, 'Número de orden inválido')
        .max(14, 'Número de orden inválido')
        .required('Debe un número de orden.'),
});

const Container = styled.div`
    position:relative;
    width:100%;
    flex:${props => props.flex};
    
    .contenedor{
        background:white;
        width:100%;
        max-width:380px;
        margin:auto;
    }
    
    
    .text{
        .title{
        font-size:32px;
        padding-bottom:5px;
        }
    }
    
    @media all and (min-width:768px){
        .contenedor{
            border-radius:6px;
            box-shadow:0 3px 8px rgba(0,0,0,.3);
            margin:auto;
        }
    }
`;



function Formulario({flex, className,...props}) {

    let {global, Setglobal} = props;
    const [state, setState] = useState({
        errors: null
    })

    //Configuracion smartlink
    //https://movistar.ordenes.epayco.co/?orden=200000012345&canal=Webpublica

    let orden = "";
    let canal = "";
    let form =  useRef(null)
    orden = getUrlParamValue(props.history, "orden")
    canal = getUrlParamValue(props.history, "canal")

    const orderDetail = async(data) => {
        const orderNumber = data.numero;
        const requestData = { number: orderNumber }         
        const response = await amonRequestHandler('POST','api/movistar/ordenes/order', requestData );
        const resource = response.data;
        
        Setglobal({loading:false})
        if (resource.success) {

            localStorage.setItem('payOrderData', JSON.stringify(resource));
            localStorage.setItem('canal', canal);
            
            props.history.push('/detalle')
        }else{
            setState({ ...state, errors: resource.data.error.message })           
        }
    }

    useEffect(() => {
        
        if (orden !== "" && form.current) {
            form.current.handleSubmit()
        }
        Setglobal({ orden, canal})

        return () => {}
    }, [])
    const updateTagManager = () =>{
        let dataLayerSubmit = {
            'event': 'trackEvent',
            'eventAction': "click",
            "eventCategory": "ordenes-epayco",
            "eventLabel": "continuar"
        }
        setNewTagManager(dataLayerSubmit)
    }
    return (
        <Formik 
            innerRef= {form}
            initialValues={{
                numero: orden || "",
            }}
            validationSchema={FormInicio}
            onSubmit={(values, {resetForm}) => {
                
                Setglobal({loading:true})
                orderDetail(values);                

            }}
        >
            {({errors, touched, setFieldValue, values, handleSubmit}) => (

                <Container className={className} flex={flex}>
                    <div className="contenedor p-2">
                        <Flex className="wc pt-2 d-md-none">
                            <Flex flex={"0 0 60px"}>
                                <Icon hg={"50px"}/>
                            </Flex>
                        </Flex>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                setState({ ...state, errors: null })
                                updateTagManager()
                                handleSubmit()

                            }}
                        >
                            <div className="text text-center pt-3">
                                <div className="wc py-3">
                                    <p className={"mb-0 title"}>Finaliza aquí tu compra</p>
                                </div>
                                <div className="wc pb-3">
                                    <p className={"mb-0"}>Ingresa tu número de orden</p>
                                </div>
                            </div>
                            <div className="wc py-3">
                                <Field
                                    className="wc px-2"
                                    icon={2}
                                    error={((errors.numero && touched.numero) && errors.numero) || state.errors}
                                >
                                    <Cleave
                                        disabled={global.loading}
                                        onChange={e => {
                                            setFieldValue('numero', e.target.rawValue)
                                        }}
                                        value={values.numero}
                                        maxLength='14'
                                        options={{
                                            numericOnly: true,
                                        }}/>
                                </Field>
                            </div>
                            <div className="wc px-2 py-3">
                                <Btn loading={global.loading}>
                                    Continuar
                                </Btn>
                            </div>
                            <Flex className="wc py-md-3">
                                <Flex className={"p-2"} flex={"0 0 auto"}>
                                    <small>Payments Processed by</small>
                                </Flex>
                                <img
                                    src={mediosP}
                                    alt=""
                                    height={"25px"}
                                    width={"auto"}
                                />
                            </Flex>
                        </form>
                    </div>
                </Container>

            )}
        </Formik>
    )
}

let globalState = state => ({
    DataHome:state.DataHome,
    global: state.Globalstate
});
let handleState = { SetDataHome, Setglobal };

export default connect(globalState, handleState )(withRouter(React.memo(Formulario)));
