import React from "react";
import styled from "@emotion/styled";
import Flex from "./../UI/flex";
import Icon from "./../UI/icon";
import {connect} from "react-redux";

const Container = styled(Flex)`
    position:relative;
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    z-index:9999;
    background:rgba(0,0,0,.8);
    
    color:white;
`;

function Mantenimiento(props) {

    let show = props.global.maintance;

    return <React.Fragment>
        {show &&
        <Container direction={"column"}>

                <Icon hg={"70px"} wd={"80px"} />
                <h1>Servicios en mantenimiento</h1>
                <p>Nuestros servicios están temporalmente fuera de línea, perdone las molestias.</p>


        </Container>
        }
    </React.Fragment>
}

let estado = state =>({global:state.Globalstate})

export default connect(estado,null)(React.memo(Mantenimiento));
