import {
    ADD_DETAIL_SHOP_DETALLE,
    ADD_NUMBER_SHOP_HOME,
    ADD_DATA_RESPONSE,
    ADD_GLOBAL_STATE
} from "./index";

export const SetDataHome = (payload)=>{
    return({
        type:ADD_NUMBER_SHOP_HOME,
        payload
    })
};

export const SetDataDetail = (payload)=>{
    return({
        type:ADD_DETAIL_SHOP_DETALLE,
        payload
    })
};

export const SetDataResponse = (payload)=>{
    return({
        type:ADD_DATA_RESPONSE,
        payload
    })
};

export const Setglobal = (payload)=>{
    return({
        type:ADD_GLOBAL_STATE,
        payload
    })
};
