import React, { useEffect} from 'react';
import "./style.scss";
import Header from "./componentes/header";
import {BrowserRouter,Route,Switch} from "react-router-dom";
import Home from "./componentes/home";
import Detalle from "./componentes/detalle";
import Respuesta from "./componentes/respuesta";
import Mantenimiento from "./componentes/MaintenancePage";
import { loadCheckoutPayments } from "./componentes/utils/index"
import {connect} from "react-redux";

const App = (props) => {

  useEffect(() => { loadCheckoutPayments() }, [])

  return (
    <React.Fragment>
      <Mantenimiento/>
      <Header/>
      <div className={` main-container ${props.global.maintance && " blur"}`} >
          <BrowserRouter>
                <Switch>
                    <Route exact path={"/"} render={()=> <Home/>} />
                    <Route exact path={"/detalle"} render={()=> <Detalle/>} />
                    <Route exact path={"/respuesta"} render={()=> <Respuesta/>} />
                </Switch>
          </BrowserRouter>
      </div>

    </React.Fragment>
  );
}


let estado = state =>({global:state.Globalstate});

export default connect(estado,null)(App);
