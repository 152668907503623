import React from "react";
import styled from "@emotion/styled";
import Flex from "./../UI/flex";
import Icon from "./../UI/icon";
import bg from "./../../img/bg.png"

const IconText = ({icon,text,flex})=>{
    return(
        <Flex className={"p-2 p-xl-3"} flex={flex}>
            <Icon icon={icon} hg={"60px"}/>
            <div className="col-12 px-0 pt-2 text-center">
                <small className={"mx-auto d-block"} style={{maxWidth: "150px"}}>{text}</small>
            </div>
        </Flex>
    )
};

const Container = styled(Flex)`
    position:relative;
    width:100%;
    color:white;
    margin:auto;
    background-size:cover;
    flex:1 0 55%;
    
    .ancho{
        width:100%;
        max-width:700px;
    }
    
    .text{
        font-size:30px; 
        line-height:1.6em;
        border-right:2px solid white;
    }
    
    
    @media all and (max-width:768px){
        flex:1 0 100%;
        background:transparent url(${bg}) no-repeat center;
        background-size:cover;
      
    }
    
     @media all and (max-width:998px){
     
       
     
         .text{
                min-width:100%;
                border-right:none;
            }
     }
`;

function Banner({flex}) {
    return (
        <React.Fragment>
            <Container className={"p-3 "} flex={flex}>
                <Flex className={"ancho"}>
                    <Flex className="text py-3 py-md-4" flex={"1 0 150px"}>
                        <p>Paga todo en un solo lugar</p>
                    </Flex>
                    <Flex className="iconos pl-md-3 pb-5 pb-md-0" flex={"1 0 350px"} alg={"flex-start"}>
                        <IconText icon={ 3} flex={ "1 0 50%" } text={ "Paga en línea todos tus productos"}/>
                        <IconText icon={ 2} flex={ "1 0 50%" } text={ "Paga con tarjeta de crédito y tarjeta de débito"}/>
                        <IconText icon={ 4} flex={ "1 0 50%" } text={ "Domicilia tus pagos con un solo clic"}/>
                        <IconText icon={ 5} flex={ "1 0 50%" } text={ "Olvidate de pagar en el banco"}/>
                    </Flex>
                </Flex>
            </Container>
            <Flex className={"wc bgb p-3 d-md-none"}>
                <small className={"mr-2"}>
                    Paymenths Processed by
                </small>
                <img
                    src={"https://multimedia-epayco.s3.amazonaws.com/Brand/PNG/epayco.png"}
                    alt=""
                    height={"26px"}
                    width={"auto"}
                />
            </Flex>
        </React.Fragment>
    )
}

export default React.memo(Banner);
