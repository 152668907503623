import React from "react";
import styled from "@emotion/styled";
import {colors} from "../colors";
import Flex from "./../flex";


const Container = styled.div`
    position:relative;
    width:100%;
    
    button{
        min-height:       ${props => props.hg }px;
        width:100%;
        color:            ${props => props.theme.color};
        background:       ${props => props.theme.bg};
        border-radius: 4px;
        border: 1px solid ${props => props.theme.brd};
        cursor : ${props => props.theme.cursor};
        font-size:20px;
        
        &:hover{
            box-shadow:0 1px 3px rgba(0,0,0,.1);   
        }
    }
    
    .loading{
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
        cursor : ${props => props.theme.cursor};
        
        .circle{
            width:${props => props.hg-10 }px;
            height:${props => props.hg-10 }px;
            border-radius:50%;
            border:4px solid rgba(0,0,0,.2);
            border-color:rgba(0,0,0,.2) rgba(0,0,0,.2) rgba(0,0,0,.2)  ${props => props.theme.loading};
            animation: girar .5s ease-in-out infinite; 
        }
    }
`;

function Btn({
    type,
    disabled,
    flex,
    className,
    onClick,
    children,
    text,
    loading,
    btnClassName,
    typeBtn="solid",
    hg="50"
             }) {

    disabled = loading ? true : disabled;

let types = {
    line:{
        bg      : disabled ? (loading ? "white" :  colors.bgBtnDisabled  ) : "white",
        color   : disabled ? (loading ? "white" : "#999999" ) : colors.verde,
        brd     : disabled ? colors.bgBtnDisabled : colors.verde,
        cursor  : loading ? "waiting" :  (disabled ? "not-allowed" :  "pointer"),
        loading : colors.verde
    },
    solid : {
        bg      : disabled ? (loading ? colors.verde :  colors.bgBtnDisabled  )  : colors.verde,
        color   : disabled ? (loading ? colors.verde :  "#999999") : "white",
        brd     : disabled ? colors.bgBtnDisabled : colors.verde,
        cursor  : loading ? "wait" :  (disabled ? "not-allowed" :  "pointer"),
        loading : "white"
    }
};



    return (
        <Container theme={types[typeBtn]} hg={hg} flex={flex} className={className}>
            <button
                onClick={onClick}
                disabled={disabled}
                className={btnClassName}
                type={type}
            >
                {children ? children : text}
            </button>
            {loading &&
                <Flex className={"loading"} direction={"column"}>
                    <div className="circle"></div>
                </Flex>
            }
        </Container>
    )
}

export default React.memo(Btn);
