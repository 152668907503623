import React from "react";
import styled from "@emotion/styled";
import Iconos from "./../../../img/iconos.png";
import micons from "./../../../img/minicons.png";

const Container = styled.div`
    position:relative;
    background: transparent url(${ props=> props.img}) no-repeat;
    background-position:center ${props=> props.icon};
    background-size:auto ${props => props.cantidad};
    height:${props=> props.hg};
    width:${props=> props.wd};
`;

function Icon({icon,hg,wd="100%",className}) {

    let iconos = {
        1:0,
        2:16.666,
        3:16.666* 2,
        4:16.666* 3,
        5:16.666* 4,
        6:16.666* 5,
        7:100,
    };

    return (
        <Container
            icon={iconos[icon]+ "%"}
            hg={hg}
            cantidad={"700%"}
            img={Iconos}
            wd={wd}
            className={className}
        >
        </Container>
    )
}

export default React.memo(Icon);

export function MinIcon({icon,hg,wd="100%"}) {

    let iconos = {
        1:0,
        2:50,
        3:100,
    };

    return (
        <Container icon={iconos[icon]+ "%"} hg={hg} img={micons} cantidad={"300%"} wd={wd}>
        </Container>
    )
}

