import {ADD_DETAIL_SHOP_DETALLE} from "../actions";

const iniState = {
    order: 'Sin informacion.',
    phoneNumber:'Sin informacion.',
    product:'Sin informacion.',
    amount:'Sin informacion.',
    expiration:'Sin informacion.'
};

let DataDetail;

DataDetail = (state = iniState, action) => {
    let value = action.payload;

    switch (action.type) {
        case ADD_DETAIL_SHOP_DETALLE :
            return {...state,...value};
        default:
            return state;
    }
};

export default DataDetail;
