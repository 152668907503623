import DataHome from "./DataHome";
import DataDetail from "./DataDetail";
import {combineReducers} from "redux";
import DataResponse from "./DataResponse";
import Globalstate from "./GeneralesStates"

export const Reducers = combineReducers({
    DataDetail,
    DataHome,
    DataResponse,
    Globalstate
}) ;
