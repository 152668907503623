export const convertirFecha = (fechaISO) => {
    const meses = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];
    const partes = fechaISO.split(" ");
    const fechaPartes = partes[0].split("-");
    const dia = parseInt(fechaPartes[2]);
    const mes = meses[parseInt(fechaPartes[1]) - 1];
    const anio = parseInt(fechaPartes[0]);
    return `${dia} de ${mes} de ${anio}`;
};

export const getParameterByName = (name) => {
    let params = new URLSearchParams(window.location.search);
    return params.get(name);
};

export const formatDate = (date) => {
    const partesFecha = date.split("-");
    const anio = partesFecha[0];
    const mes = partesFecha[1];
    const dia = partesFecha[2];
    const nuevaFecha = `${dia}/${mes}/${anio}`;
    return nuevaFecha;
}
