import React from "react";
import styled from "@emotion/styled";
import Flex from "./../UI/flex";
import Icon from "./../UI/icon";
import {colors} from "../UI/colors";
import payments from "../../img/mediosPagos.png";

const Container = styled(Flex)`
    position:relative;
    width:100%;
    max-width:285px;
    margin:auto;
    
    .icon{
        flex:0 0 70px;
        height:50px;
    }
    
    .text{
        flex:1 0 60%;
       
        .name{
            font-weight:bold;
            font-family:'telefonica_textbold';
            font-size:12px;
            color:50535a;
            width:100%;
            text-transform:uppercase;
        }
        .value{
            font-size:20px;
        }
    }
`;


const ContainerProduct = styled.div`
    width:100%;
    max-width:285px;
    border:1px solid #c0c0c0; 
           
        .name{
            font-weight:bold;
            font-family:'telefonica_textbold';
            font-size:12px;
            color:50535a;
            width:100%;
            text-transform:uppercase;
        }
        .total{
            font-size:24px;
            color:${colors.verde};
        }
    }
`;

export function ItemDetail({name, value, icon, className}) {
    return (
        <Container className={className} jc={"flex-start"}>
            <div className="icon">
                <Icon hg={"43px"} icon={icon}/>
            </div>
            <div className="text">
                <div className="name">
                    {name}
                </div>
                <div className="value mb-2">
                    {value}
                </div>
            </div>
        </Container>
    )
}

export const ProductSection = ({producto,total}) => {
    return (
        <ContainerProduct className={"py-3 px-4"}>
            <div className="mb-3">
                <div className="name">
                    producto
                </div>
                <div className="value">
                    {producto}
                </div>
            </div>
            <div className="div">
                <div className="name">
                    valor
                </div>
                <div className="total">
                    ${total}
                </div>
            </div>
        </ContainerProduct>
    )
};

export const MediosPagosFooter = ()=>{
    return(
        <Flex flex={"0 0 auto"} className={"col-12 col-xl-10 mx-auto px-xl-0 justify-content-md-end"}>
            <small className={"mr-2 py-2"}>
                Paymenths Processed by
            </small>
            <img src={payments} alt="" height={"26px"}  width={"auto"} className={"mx-2"} />
            <img
                src={"https://multimedia-epayco.s3.amazonaws.com/Brand/PNG/epayco.png"}
                alt=""
                height={"26px"}
                width={"auto"}
            />
        </Flex>
    )
}





