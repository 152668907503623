import axios from "axios";
import TagManager from "react-gtm-module/dist/TagManager";

/**
 * Servicio general para ejecutar peticiones http sin autenticacion
 * @param {string} method POST o GET
 * @param {string} url Ruta objetivo a donde se lanzara la peticion
 * @param {object} data Valores a enviar a la ruta objetivo
 * @param {boolean} hasAuth  Lanzar peticion con autenticacion
 * @param {string} authType basic, bearer
 */
export const requestHandler = async (method, url, data, hasAuth, authType = null) => 
{
    
    let configuration = {};

    try {
        if(hasAuth)
        {
            if (authType === 'basic' ) {
                configuration ={
                    method,
                    url,
                    auth : {
                        username: data.username,
                        password: data.password
                    }
                }
            }else{

                const token = data.token;
                delete data.token;

                configuration ={
                    method,
                    url,
                    data,
                    headers : {
                        Authorization: `Bearer ${token}`
                    }
                }
            }

        }else{
            configuration ={
                method,
                url,
                data
            }
        }

        return await axios(configuration);

    } catch (error) {
        console.log("Error: requestHandler");
        console.log(error);
    }

}

/**
* Servicio para hacer peticiones autenticadas a Amon 
* @param {string} method POST o GET
* @param {string} route Ruta objetivo a donde se lanzara la peticion
* @param {object} data Valores a enviar a la ruta objetivo
*/
export const amonRequestHandler  = async (method, route, data) => {

    try {
        const amonBaseUrl = process.env.REACT_APP_AMON_BASE_URL;
        const auth = { username: process.env.REACT_APP_PUBLIC_KEY, password: process.env.REACT_APP_PRIVATE_KEY }
        const response = await requestHandler('POST', `${amonBaseUrl}/login`, auth, true, 'basic');

        //TODO: crear metodo para almacenar token en localStorage y utilizarlo hasta su vencimiento, para cuando expire solitar de nuevo

        data.token = response.data.token;
        return await requestHandler(method, `${amonBaseUrl}/${route}`, data, true, 'bearer');
    } catch (error) {
        console.log("Error: amonRequestHandler");
        console.log(error);
    }

}

/**
 * Pre-carga del checkout de pagos en el html
 */
export const loadCheckoutPayments = () => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_CHECKOUT_PAYMENTS_SCRIPT;
    document.body.appendChild(script);
}

/**
 * Metodo requierdo para renderizar el checkout de pagos
 * @param {boolean} enableButtons Accion callBack para activar nuevamente el boton de pagar
 */
export const renderCheckoutPayments = (configData, enableButtons) => {  

    const ePayco = window.ePayco;

    let handler = ePayco.checkout.configure({
        key: process.env.REACT_APP_PUBLIC_KEY,
        test: process.env.REACT_APP_CHECKOUT_PAYMENTS_TEST //Modo produccion
    });

    // const elements = ePayco.elements.create('general', {
    //     modules: {
    //         countryBarEnabled: false,
    //         duesSelected: false
    //     }
    // });

    const data =
    {
        name: "Movistar Ordenes",
        description: `Pago de orden: ${configData.order}`,
        invoice: configData.order,
        currency: "cop",
        amount: configData.amount,
        tax_base: "0",
        tax: "0",
        country: "co",
        lang: "es",
        external: "false",
        response: `${process.env.REACT_APP_ORDERS_BASE_URL}/respuesta`,
        confirmation: `${process.env.REACT_APP_AMON_BASE_URL}/api/movistar/ordenes/payorder/on/confirmation`,
        force_response: true,
        //Configuracion de extras Movistar Ordenes
        extra1: configData.order, //Orden
        extra2: configData.phoneNumber, //Numero de linea
        extra3: configData.canal, //Canal de pago {Puede ingresar por URL}
        extra4: "",//Reservado para numero paymentSN {Confirmacion de pago aplicado}
        extra5: "epayco_ordenes"
       
    };

    handler.open(data);
    handler.onClosed(()=> enableButtons());

}


export const getUrlParamValue = (history, paramToSearch) =>  new URLSearchParams(history.location.search).get(paramToSearch) || "";

export const initializeTagManager = () => {
        const tagManagerArgs = {
            gtmId: 'GTM-KBVPPT'
        }
        TagManager.initialize(tagManagerArgs)
    }
export const setNewTagManager = (dataLayer) => {
        let tagManagerArgs = {
            gtmId: 'GTM-KBVPPT',
            dataLayer
        }
        TagManager.dataLayer(tagManagerArgs)
}
