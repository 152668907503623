import React     from "react";
import styled    from "@emotion/styled";
import {MinIcon} from "../UI/icon";
import Flex      from "./../UI/flex";
import {colors}  from "../UI/colors";
import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.co";

const Container = styled.div`
    position:relative;
    border-top: 10px solid ${props =>props.brd};
    box-shadow:0 1px 5px rgba(0,0,0,.3);
`;

const NameValueCont = styled.div`
    flex:${props => props.flex};
    background:transparent;
   
   .gost{
    background:${props => props.theme.bgColor};
    background-size: 600% 600%;
    transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
    animation: ${props => props.theme.bg}; 
    border-radius:8px;
   }
   
    
    .name{
        width:100%;
        font-size:12px;
        text-transform:uppercase;
        font-family: ${props=> props.theme.name.font};
        opacity:     ${props=> props.theme.name.opacity};
        transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
    }
    
    .value{
        width:100%;
        font-family:    ${props=> props.theme.value.font};
        color:          ${props=> props.theme.value.color};
        text-transform: ${props=> props.theme.value.case};
        opacity:        ${props=> props.theme.value.opacity};
        font-size:20px;
        transition: all 500ms cubic-bezier(0.075, 0.820, 0.165, 1.000);
        
        img{
            max-height:48px;
            width:auto;
            display:block;
        }
    }
`;

const BtnPrint = styled.div`
    height:50px;
    width:100%;
    border-top:1px solid #cbcbcb;
    cursor:pointer;
    
    &:hover{
        background:rgba(0,0,0,0.05);
    }
`;

const LoadingS = styled.div`
    height:50px;
    width:100%;
    border-top:1px solid #e4e4e4:
    width:100%;
    height:10px;
    border-radius:5px;
    background:#f1f1f2;
    overflow:hidden;
    
    
    .carga{
        border-radius:5px;
        height:100%;    
        width:100%;
        background:${colors.verde};
        
        animation: carga 5s ease-out infinite;
     }
`;

const Loading = ()=>{
    return(
        <LoadingS>
            <div className="carga"></div>
        </LoadingS>
    )
};


const NameValue = ({gost,flex="50%" ,name= "--------",value= "--------",valuebold,nameBold,franchise, caseUp,typeValue="normal",currency=" COP",loading=false,color})=>{

    let theme = {
        bg: gost ? "gost 3s ease infinite" : "none",
        bgColor: gost ? "linear-gradient(89deg, #f1f1f2, #d9d9d9, #f1f1f2)" : "none",
        name:{
            font : nameBold  ? 'telefonica_textbold' : 'telefonica_textregular',
            opacity: gost ? 0 : 1,
        },
        value:{
           font  : valuebold ? 'telefonica_textbold':'telefonica_headline_lightRg',
           case  : caseUp   ? "uppercase" : "initial",
           color : color     ? color : "#50535a",
            opacity: gost ? 0 : 1,
        }
    };

    let values = {
        img    : <img src={`https://multimedia-epayco.s3.amazonaws.com/dashboard/cards/${franchise}.png`} alt=""/>,
        amount : <Cleave value={value} options={{ delimiter:".",prefix:"$",numeral:true,numeralThousandsGroupStyle: 'thousand', }} />,
        phone  : <Cleave value={value} options={{ blocks:[3,3,4]}} /> ,
        date   : ""  ,
        normal : value ,
    };

    return(
        <NameValueCont
            flex={`1 0 ${flex}`}
            theme={theme}
            className={"p-2 px-md-3 px-lg-4"}
        >
            <div className="gost">
                <div className="name">
                    {name}
                </div>
                <div className="value">
                    {loading ?
                        <div className={"py-2"}>
                            <Loading/>
                        </div>
                        :
                        values[typeValue]
                    }
                </div>
            </div>
        </NameValueCont>
    )
};

function CuadroResponse({borde,data=[],print= null,loading,nameBold,title,procesando, pagoPendiente}) {
    return (
        <div className={"wc"}>
            <div className="wc py-4 text-center ">
                <p className={"mb-0 fl"} style={{fontSize:"26px"}}>{title}</p>
            </div>
            <Container brd={borde}>
                <Flex alg={"flex-start"} jc={"flex-start"} className={"wc py-2"}>
                    {data.map((item,index)=>
                        <NameValue
                            key       = {index}
                            name      = {item.name}
                            value     = {item.value     || undefined}
                            typeValue = {item.typeValue || undefined}
                            valuebold = {item.bold}
                            franchise = {item.franchise}
                            flex      = {item.flex || undefined}
                            nameBold  = {nameBold}
                            caseUp    = {item.case || undefined }
                            color     = {item.color || undefined }
                            loading   = {item.loading || undefined }
                            gost      = { procesando }
                        />
                    )}
                    { pagoPendiente && 
                        <div className="col-12">
                            <small> 
                                <em>No te preocupes, revisaremos tu transacción y aplicaremos el pago de tu compra </em>
                            </small>
                        </div> 
                    }  
                </Flex>

                {print &&
                <BtnPrint onClick={print} className={" py-1"}>
                   <Flex className={"wc py-2"}>
                       <Flex flex={"0 0 20px"}>
                           <MinIcon hg={"20px"} icon={0} />
                       </Flex>
                       <Flex>
                           <span className={"ml-2"} style={{color:colors.azul}}>Imprime este comprobante</span>
                       </Flex>
                   </Flex>
                </BtnPrint>
                }
            </Container>
        </div>
    )
}


export default React.memo(CuadroResponse);
