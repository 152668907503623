import styled from "@emotion/styled";
import Flex from "./../UI/flex";

export const NewResponseContainer = styled(Flex)`
    position: relative;
    width: 100%;
    background: #ededed;
    min-height: 100%;
    font-family: "telefonica_textregular";
    a:link,
    a:visited,
    a:hover,
    a:active {
        color: #000000;
        text-decoration: none;
    }
    h4,
    p {
        margin: 0px;
    }

    .header-image {
        max-width: 585px;
        margin: 0 auto;
        display: block;
        width: 100%;
    }
    .head-tittle {
        font-family: "telefonica_textbold";
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        line-height: 38px;
        margin: 0 auto;
        /* identical to box height, or 136% */
        text-align: center;
        display: block;
        color: #019df4;
        @media (max-width: 576px) {
            max-width: 253px;
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;
            text-align: center;
        }
    }
    .status-info {
        font-size: 20px;
        line-height: 21px;
        text-align: center;
        color: #424b5a;
        margin: 7px auto 40px auto;
        @media (max-width: 576px) {
            max-width: 253px;
            font-size: 16px;
        }
    }
    .bar-info {
        background: #ffffff;
        border-radius: 8px;
        width: 100%;
        justify-content: space-evenly;
        flex-flow: row nowrap;
        @media (max-width: 1140px) {
            flex-flow: column nowrap;
            align-self: start;
        }
        img {
            width: 48px;
            margin: 20px 24px 20px 0px;
        }
        .info-container {
            @media (max-width: 1140px) {
                align-self: start;
            }
            flex-flow: row nowrap;
            h4 {
                font-family: "telefonica_textbold";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                color: #019df4;
            }
            p {
                color: #424b5a;
            }
        }
    }
    .title-buy-detail {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 42px;
        font-weight: 700;
        font-size: 28px;
        line-height: 24px;
    }
    .detail-buy-container {
        justify-content: space-between;
        margin-bottom: 30px;
        .description-buy {
            color: #313235;
            font-size: 14px;
            .row-table {
                padding: 17px 0;
                text-align: left;
                display: flex;
                flex-flow: row nowrap;
                border-bottom: 1px solid #d3d4d3;
                span {
                    align-self: start;
                    width: 35%;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 28px;
                    color: #313235;
                    @media (max-width: 389px) {
                        width: 130px;
                        height: auto;
                    }
                    @media (max-width: 576px) {
                        font-size: 16px;
                    }
                }
                p {
                    width: 65%;
                    line-height: 18px;
                    align-self: center;
                }
            }
            width: 57%;
            @media (max-width: 1140px) {
                width: 100%;
            }
        }
        .detail-buy {
            align-self: start;
            width: 40%;
            @media (max-width: 1140px) {
                width: 100%;
            }
        }
    }
    .powerby-epayco {
        width: 100%;
        text-align: center;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        margin: 32px auto;
        img {
            display: block;
        }
        p {
            margin: 0 8px;
        }
        .logo-epayco {
            width: 49.33px;
            height: 15.13px;
        }
        .icon-union {
            width: 8.73px;
            height: 11.35px;
        }
    }
    .amount-total {
        font-family: "telefonica_textbold";
        font-style: normal;
        font-weight: 700;
        background: #ffffff;
        border-radius: 8px;
        width: 100%;
        height: 58px;
        display: flex;
        padding: 18px 35px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 18px;
    }
    .info-movistar {
        margin-bottom: 25px;
        flex-flow: row nowrap;
        img {
            width: 58px;
            height: 58px;
            display: block;
            margin-right: 24px;
        }
        h4 {
            font-family: "telefonica_textbold";
        }
    }
    .apps-buttons {
        flex-flow: row nowrap;
        justify-content: space-between;
        margin-bottom: 40px;
        img {
            width: 161px;
            height: 48px;
            @media (max-width: 389px) {
                width: 92%;
                height: auto;
                justify-content: space-between;
            }
        }
        @media (max-width: 1140px) {
            justify-content: space-evenly;
        }
    }
    .button-goto-movistar {
        display: block;
        margin: 0 auto;
        background: #019df4;
        border-radius: 60px;
        align-items: center;
        padding: 16px 48px;
        gap: 12px;
        width: 327px;
        height: 56px;
        font-weight: 700;
        a {
            color: #ffffff;
        }
        @media (max-width: 389px) {
            width: 100%;
        }
    }
    .button-imprimir-movistar{
        background: #fff;
        margin-top: 20px;
        padding: 0px;
    }
    .activation-info {
        flex-flow: row nowrap;
        align-items: center;
        margin-top: 17px;
        justify-content: center;
        margin-bottom: 30px;
        img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
            display: block;
        }
        p {
            text-align: left;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.0015em;
            color: #313235;
        }
    }
    .font-18 {
        font-size: 18px;
        @media (max-width: 576px) {
            font-size: 16px;
        }
    }
    .font-14 {
        font-size: 14px;
    }
`;
