import React from "react";
import styled from "@emotion/styled";
import {Collapse} from "react-collapse";
import {colors} from "../colors";
import {MinIcon} from "../icon";
import Flex from "../flex";

const Container = styled.div`
    position:relative;
    width:100%;
    flex:${props => props.flex};
    
    label{
        font-size:14px;
        color: ${props => props.label};
    }
    
    input,select{
        width:100%;
        border-radius:4px;
        height:50px;
        padding: 5px 5px 5px 40px;
       
        background : ${props => props.bg};
        color      : ${props => props.color};
        border:1px solid ${props => props.brd};
        
        &:disabled{
            cursor:not-allowed;
        }
    }
    
    .icon{
        position:absolute;
        top:0;
        left:0;
        width:40px;
        height:50px;
        z-index:3;
    }
    
 
    small.error{
        color:red;
    }
`;

function Field({error=null, label,children,className,flex,icon}) {
    return (
        <Container
            flex={flex}
            className={className}
            bg={colors.bgBtnDisabled}
            color={colors.textBtnDisabled}
            brd={error !== null ? "red" : colors.bgBtnDisabled}
        >

            {label && <label htmlFor="">{label}</label>}
                <div className="wc position-relative">
                {children}

                {icon &&
                <Flex className="icon" direction={"column"}>
                    <MinIcon hg={"25px"} icon={icon}/>
                </Flex>
                }
            </div>
            <Collapse isOpened={true}>
                <small className={"error"}>{error}</small>
            </Collapse>
        </Container>
    )
}

export default React.memo(Field);
