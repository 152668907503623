import React from "react";
import { LoadingStyled } from "./style";

const Loading = () => {
  return (
    <LoadingStyled>
      <div className="loadingio-spinner-dual-ring-0vpzkyvcrwt">
        <div className="ldio-w25ajxc6pq">
          <div></div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </LoadingStyled>
  );
};


export default Loading;
