import {ADD_DATA_RESPONSE} from "../actions";

const iniState = {
    loading:true,
    aplicandoPago:true,
    transaction:{
        empresa:"COLOMBIA TELECOMUNICACIONES S.A ESP",
        nit:"830 123 566-1",
        fechaHora:"Sin información.",
        descripcion:"Sin información.",
        ipOrigen:"Sin información.",
        metodoPago:"Sin información.",
    },
    invoice:{
        numeroOrden:"Sin información.",
        estadoTransaccion:"Sin información.",
        referencia:'Sin información.',
        numeroLinea:"Sin información.",
        refPayco:'Sin información.',
        valor:"Sin información.",
        estadoPago:"Sin información.",
        autorizacion:"Sin información."
    },
};

let DataResponse;

DataResponse = (state = iniState, action) => {
    let value = action.payload;

    switch (action.type) {
        case ADD_DATA_RESPONSE :
            return {...state,...value};
        default:
            return state;
    }
};


export default DataResponse;
