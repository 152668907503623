import {ADD_NUMBER_SHOP_HOME} from "../actions";

const iniState = {
    shop_number: '',
    error:''
};

let DataHome;
DataHome = (state = iniState, action) => {
    let value = action.payload;

    switch (action.type) {
        case ADD_NUMBER_SHOP_HOME :
            return {...state,...value};
        default:
            return state;

    }
};

export default DataHome;


