import React from "react";
import styled from "@emotion/styled";
import Flex from "./../UI/flex";
import LogoM from "./../../img/logoName.png";
import {connect} from "react-redux";


const Container = styled.div`
    position:relative;
    width:100%; 
    position:absolute;
    top:0;
    left:0;
    z-index:3;
    background:white;
    box-shadow:0 2px 5px rgba(0,0,0,0.2);
    filter:blur(${props => props.blur});
`;

function Header(props) {

    let blur = props.global.maintance ? "5px" : "0";

    return (
        <Container className={"d-none d-md-block py-3"} blur={blur}>
            <Flex className={"col-12 col-xl-10 mx-auto px-xl-0"} jc={"space-between"}>
                <Flex flex={"0 0 auto"}>
                    <img
                        src={LogoM}
                        alt=""
                        height={"40px"}
                        width={"auto"}
                    />
                </Flex>
                <Flex flex={"0 0 auto"} jc={"flex-end"}>
                    <small className={"mr-2"}>
                        Paymenths Processed by
                    </small>
                    <img
                        src={"https://multimedia-epayco.s3.amazonaws.com/correos/correos_ePayco/epayco.png"}
                        alt=""
                        height={"26px"}
                        width={"auto"}
                    />
                </Flex>
            </Flex>
        </Container>
    )
}

let estado = state => ({global :state.Globalstate});

export default connect(estado,null)(React.memo(Header));
