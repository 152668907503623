import React, {useEffect, useState} from "react";
import styled from "@emotion/styled";
import CuadroResponse from "./helpers";
import SendEmail from "./sendEmail";
import {colors} from "../UI/colors";
import Flex from "./../UI/flex";
import {MediosPagosFooter} from "../detalle/helper";
import Btn from  "./../UI/button";
import {amonRequestHandler, getUrlParamValue, setNewTagManager} from "./../utils/index"
import { withRouter, useLocation } from "react-router-dom";
import {connect} from "react-redux";
import {SetDataResponse} from "../../store/actions/actionsCreators";
import NewResponse from "../newResponse/index.jsx";
import Loading from "../UI/Loading/index";

const Container = styled(Flex)`
    position:relative;
    width:100%;
    min-height:100%;
    
    @media all and (min-width:998px){
        padding-top:72px;
    }
`;

function Respuesta(props) {

    const { 
        SetDataResponse, 
        dataResponse:{
            loading,
            aplicandoPago,
            transaction,
            invoice,
            allResponse
    }} = props;
    
    const [state, setState] = useState({
        successfullyPayment: false,
        hasResponse: false,
        validTransactionState: false,
        transactionState: "",
        emailLabel: "Enviar copia del comprobante de transacción",
        emailSended : false
    })

    /**
     * Consultar detalle de transaccion epayco
     * @param {string} refPayco Referencia de pago obtenida en la redireccion a la pagina de respuesta desde el checkout
     */
    const createTransactionView = async (refPayco) => {
        const requestData = { ref_payco: refPayco }

        let result = {
            success: false,
            data: {},
            all: {}
        }

        try {

            const response = await amonRequestHandler('POST','api/movistar/ordenes/payorder/on/transaction', requestData );
            const resource = response.data;
            if (resource.success) {
                result.success = true;
                result.all = resource;
                result.data = {
                    transaction : {
                        extra6: resource.data.x_extra6,
                        empresa: resource.data.x_business,
                        nit: "830 123 566-1",
                        fechaHora: resource.data.x_fecha_transaccion,       
                        descripcion: resource.data.x_description,
                        ipOrigen: resource.data.x_customer_ip,
                        metodoPago: resource.data.x_franchise,
                        extra10: resource.data.x_extra10,
                    },
                    invoice: {
                        numeroOrden: resource.data.x_id_invoice,
                        estadoTransaccion: resource.data.x_transaction_state,
                        referencia: resource.data.x_id_invoice,
                        numeroLinea: resource.data.x_extra2,
                        refPayco: resource.data.x_ref_payco,
                        valor: resource.data.x_amount,
                        estadoPago: "Sin información.",
                        autorizacion: resource.data.x_approval_code
                    }    
                }
                if(resource.data.x_extra6 != "Portabilidad" && resource.data.x_extra6 != "Linea Nueva"){
                    window.ci360("send", {
                        "eventName": "custom",
                        "event":"custom",
                        "customGroupName":"custom",
                        "customRevenue":"0",
                        "apiEventKey": "finalizacion-compra",
                        
                        "estado_transaccion": resource.data.x_transaction_state,

                        "linea": resource.data.x_extra2,
                        "referencia": resource.data.x_id_invoice,
                        "referencia_epayco": resource.data.x_ref_payco,
                        "valor": resource.data.x_amount

                    })
                }
                if (resource.data.x_transaction_state == "Aceptada") {
                    if ((resource.data.x_extra6 == "Portabilidad") || (resource.data.x_extra6 == "Linea Nueva")) {
                        let dataLayerTrackTransaction = {
                            'event': 'trackTransaction',
                            'productos': [{
                                'name': `${resource.data.x_extra6} ${resource.data.x_extra6}`,
                                'id': `${resource.data.x_extra1}`,
                                'price': `${resource.data.x_ref_payco}`,
                                'brand': 'MOVISTAR',
                                'category': `${resource.data.x_extra6} PREPAGO B2C`,
                                'variant': 'SIMCARD',
                                'coupon': ''
                            }]
                        }
                        setNewTagManager(dataLayerTrackTransaction)
                        let dataLayerTrTransaction = {
                            'event': 'trTransaction'
                        }
                        setNewTagManager(dataLayerTrTransaction)
                        let dataLayerPageView = {
                            'event': 'trackPageview',
                            'pageName': `/eshop-${resource.data.x_extra6}-prepago-B2C/thank-you-page/exitosa`
                        }
                        setNewTagManager(dataLayerPageView)
                    }
                    if ((resource.data.x_extra6 == "equipos") || (resource.data.x_extra6 == "Accesorios")) {
                        let dataLayerTrackTransaction = {
                            'event': 'trackTransaction',
                            'productos': [{
                                'name': `${resource.data.x_extra6} ${resource.data.x_extra1}`,
                                'id': `${resource.data.x_extra1}`,
                                'price': `${resource.data.x_ref_payco}`,
                                'brand': 'MOVISTAR',
                                'category': `${resource.data.x_extra6} B2C`,
                                'variant': '',
                                'list': `eshop ${resource.data.x_extra6} B2C`
                            }]
                        }
                        setNewTagManager(dataLayerTrackTransaction)
                        let dataLayerTrTransaction = {
                            'event': 'trTransaction'
                        }
                        setNewTagManager(dataLayerTrTransaction)
                        let dataLayerPageView = {
                            'event': 'trackPageview',
                            'pageName': `/eshop-${resource.data.x_extra6}--B2C/thank-you-page/`
                        }
                        setNewTagManager(dataLayerPageView)
                    }
                }
            }
            
        } catch (error) {
            console.error(error); //Definir estructura de respuesta del catch    
        }
        
        return result;
    }

    /**
     * Proceso de aplicacion de pago orden
     * @param {string} refPayco Referencia de pago obtenida en la redireccion a la pagina de respuesta desde el checkout
     */
    const createPaymentOrderProcess = async (refPayco) => {
        const requestData = { ref_payco: refPayco }
        let result = { paymentApplied: false };

        try {

            const response = await amonRequestHandler('POST','api/movistar/ordenes/payorder/on/response', requestData );
            if(response){
                const resource = response.data;
                result.paymentApplied = resource.success
                //result.paymentApplied = true //Simular pago aplicado
                return result
            }
            return {
                paymentApplied: true
            };
            
        } catch (error) {
            console.error(error); //Definir estructura de respuesta del catch    
        }
        
    }

    /**
     * Estructurar informacion del comprobante de compra
     * @param {boolean} showPaymentProcess 
     * @param {string} transactionState 
     */
    const purchaseDetail = (showPaymentProcess, transactionState) => {

        let selectedColor = "";
        let isBold = false
        let transactionName = invoice.estadoTransaccion

        if (transactionState === "Rechazada") {
            selectedColor = colors.rojo;
            isBold = true;
            transactionName = "¡Transacción rechazada!";            
        }

        if (transactionState === "Pendiente") {
            selectedColor = colors.naranja;
            isBold = true;
            transactionName = "Transacción pendiente";            
        }

        let purchaseDetail =  
        [
            {
                name      :"nro. de compra",
                value     :invoice.numeroOrden,
                bold      : true
            },
            {
                name      :"estado de la transacción",
                value     : transactionName,
                color     : selectedColor,
                bold      : isBold

            },
            {
                name      :"referencia",
                value     : invoice.referencia,

            },
            {
                name      :"nro de línea",
                value     :invoice.numeroLinea,
            },
            {
                name      :"referencia epayco",
                value     : invoice.refPayco,

            },
            {
                name      :"valor",
                value     : invoice.valor,
            },
            {
                name      :"estado aplicación del pago",
                value     : invoice.estadoTransaccion === "Aceptada" ? "Aplicación exitosa": "Aplicación pendiente",
                bold      : !aplicandoPago,
                color     : invoice.estadoTransaccion === "Aceptada" ? colors.verde: colors.naranja,
                loading   : aplicandoPago
            },
            {
                name      :"autorización",
                value     :invoice.autorizacion,
            },
        ]

        if (!showPaymentProcess) {
            purchaseDetail.splice(6,1); //No mostrar linea de carga
        }

        return purchaseDetail;
    }

    /**
     * Realiza envio de comprobante de transaccion | formato epayco
     * @param {string} email 
     */
    const executeSendEmail = async (email) => {
        const requestData = { ref_payco: invoice.refPayco, email }
        const response = await amonRequestHandler('POST','api/movistar/ordenes/payorder/voucher', requestData );
        const resource = response.data;
        if (resource.success) {            
            setState({...state, 
                emailLabel: "¡Mensaje enviado correctamente!",
                emailSended: true
              }) 
            setTimeout(() => {
                setState({...state, emailLabel: "Enviar copia del comprobante de transacción"  }) 
            }, 2000);
        }

    }

    useEffect(()=>{
        
        const reference = getUrlParamValue(props.history, "ref_payco");
        const fetchTransactionView = async () => {
            const responseTransactionView = await createTransactionView(reference)
            if (responseTransactionView.success) {
                
                setState({...state, transactionState: responseTransactionView.data.invoice.estadoTransaccion }) 

                SetDataResponse({
                    loading:false,
                    transaction: responseTransactionView.data.transaction,
                    invoice: responseTransactionView.data.invoice,
                    allResponse: responseTransactionView.all
                })
                
                if (responseTransactionView.data.invoice.estadoTransaccion === "Aceptada" ) {
                    setState({...state, validTransactionState: true }) 
                    SetDataResponse({aplicandoPago:true})
                    fetchPaymentOrderProcess();
                }else{
                    await createPaymentOrderProcess(reference)
                    SetDataResponse({aplicandoPago:false})
                }
               
            }
        }
        
        let i = 0; //Control contador de intentos de aplicar
        const fetchPaymentOrderProcess= async() => {

            if (i ===  parseInt(process.env.REACT_APP_ATTEMPTS) ){
                setState({
                    ...state,
                    hasResponse : true,
                    successfullyPayment: false,
                    validTransactionState: true
                })                 
                SetDataResponse({ aplicandoPago:false  })    
            }else{
                const responsePaymentOrderProcess = await createPaymentOrderProcess(reference)
                if (responsePaymentOrderProcess.paymentApplied) {

                    setState({
                        ...state,
                        hasResponse : true,
                        successfullyPayment: true,
                        validTransactionState: true
                    })
                    SetDataResponse({ aplicandoPago:false }) 
                }else{
                    i++;
                    await fetchPaymentOrderProcess()
                }
            }
        }
        
        fetchTransactionView();
        
    },[]);
    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    return (
        <>
            {loading ? (
                <Container direction={"column"}><Loading /></Container>
            ) : (<>
                {
                    (transaction.extra6 === "Portabilidad" || transaction.extra6 === "Linea Nueva")
                    &&
                    isJson(transaction.extra10) ?
                (<NewResponse dataResponse={allResponse} loading={loading} extra6={transaction.extra6} />):
                (<Container direction={"column"}>
                    <Flex className={"wc"} flex={"1 0 80%"}>
                    <Flex className="col-12 col-xl-10 px-2 px-sm-4 mx-auto px-xl-0" alg={"flex-start"} >
                        <div className="col-12 col-md-6 px-0  pr-md-4 pb-4 pb-md-0">
                            <CuadroResponse
                                procesando={loading}
                                title={"Resultado de la transacción"}
                                nameBold
                                borde={colors.azul}
                                data={[
                                    {
                                        name      :"empresa",
                                        value     : transaction.empresa,
                                        flex      :"100%",
                                        case      : true
                                    },
                                    {
                                        name      :"nit",
                                        value     :transaction.nit,
                                    },
                                    {
                                        name      :"fecha y hora",
                                        value     :transaction.fechaHora,
                                    },
                                    {
                                        name      :"descripción",
                                        value     :transaction.descripcion,
                                    },
                                    {
                                        name      :"ip origen",
                                        value     :transaction.ipOrigen,
                                    },
                                    {
                                        name      :"método de pago",
                                        flex      :"100%",
                                        typeValue :"img",
                                        franchise : transaction.metodoPago
                                    },
                                ]}
                            />
                            <div className="wc pt-2">
                                <small className={`fi ${ state.emailSended && "cv"}`}>{ state.emailLabel }</small>
                                <SendEmail
                                    loading={false}
                                    disabled={loading}
                                    sendEmail={(email)=> { 
                                        executeSendEmail(email)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-0 pl-md-4">
                            <CuadroResponse
                                procesando={loading}
                                title={"Comprobante de la compra"}
                                print={()=> window.print()}
                                borde={colors.verde}
                                pagoPendiente={!state.successfullyPayment && state.hasResponse}
                                data={purchaseDetail(state.validTransactionState, state.transactionState)}
                            />
                            <div className="wc pt-3">
                                <Btn
                                    text={"Finalizar"}
                                    disabled={loading}
                                    onClick={()=> props.history.push("/")}
                                />
                            </div>
                        </div>
                    </Flex>
                </Flex>
        </Container>)}
        <div className="wc mx-auto text-center py-4">
            <MediosPagosFooter/>
        </div>
        </>)}
    </>
    )
}



const mapStateToProps = state => ({
    dataResponse : state.DataResponse,
});

const mapDispatchToProps = {SetDataResponse};

export default connect(mapStateToProps,mapDispatchToProps)(React.memo(withRouter(Respuesta)));
