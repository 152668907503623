import {ADD_GLOBAL_STATE} from "../actions";

const init = {
    maintance:false, // PANTALLA DE MANTENIMIENTO
    loading: false,
};

const Globalstate = (state=init,action)=>{
    switch (action.type) {
        case ADD_GLOBAL_STATE:
            return {...state,...action.payload};
        default:
            return state;
    }
};

export default Globalstate;
