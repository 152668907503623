import React , {Fragment, useEffect, useState} from "react";
import styled from "@emotion/styled";
import Flex from "./../UI/flex";
import {ItemDetail,ProductSection,MediosPagosFooter} from "./helper";
import Btn from "./../UI/button";
import {colors} from "../UI/colors";
import {SetDataDetail, Setglobal} from "../../store/actions/actionsCreators";
import {connect} from "react-redux";
import Icon from "./../UI/icon";
import { renderCheckoutPayments } from "./../utils/index"
import { withRouter } from "react-router-dom";


const Container = styled(Flex)`
    position:relative;
    width:100%;
    min-height:100%;
    
    .contenido{
        width:100%;
        max-width:820px;
       
        .titleMain{
            font-size:26px;
            color:#50535a;
        }
        
        .vence{
            .title{
                font-size:12px;
                font-family:'telefonica_textbold';
                text-transform:uppercase;
            }
            .fecha{
                color:${colors.verde};
            }
        }
    }
    
    @media all and (min-width:998px){
        padding-top:72px;
        
        .contenido{
            max-height:475px;
        }
    }
    
    @media all and (min-width:998px) and (min-height:550px){
        .contenido{
            box-shadow:0 1px 5px rgba(0,0,0,.3);
            border-radius:10px;
        }
    }
`;



function Detalle(props) {

    const [state, setState] = useState({procesando:true});

    const { Setglobal, global, detalle } = props;

    let dataPayOrder = {}

    if (localStorage.getItem('payOrderData') === null) {
        props.history.push('/')
    }else{
        dataPayOrder = JSON.parse(localStorage.getItem('payOrderData')).data;
        dataPayOrder.canal = localStorage.getItem('canal') || "Webpublica";
    }    

    const launchCheckoutPayments = () => {
        Setglobal({loading:true})       
        renderCheckoutPayments( dataPayOrder , () => Setglobal({loading:false}) );
    }

    useEffect(() => {

        if(dataPayOrder){

             props.SetDataDetail({
                order: dataPayOrder.order,
                phoneNumber: dataPayOrder.phoneNumber,
                product: dataPayOrder.product,
                amount: dataPayOrder.amount,
                expiration: dataPayOrder.expiration
            })  
            
        }else{
            props.history.push('/')
        }
        setState({procesando:false});
        
    }, [])

    return (
        <Fragment>
         {  state.procesando ? "Procesando" :

        <Container direction={"column"}>

                <Flex flex={"1 0 auto"} className={"wcs"}>
                    <Flex className="contenido ">
                        <div className="titleMain pt-3 pt-md-4 pb-3 wc text-center">
                            <div className="wc text-center d-md-none">
                                <Icon hg={"50px"} wd={"60px"} className={"mx-auto"}/>
                            </div>
                            Información de tu cuenta
                        </div>
                        <div className="vence">
                            <span className={"title"}>VENCIMIENTO</span>
                            <p className={"fecha"}>
                                {dataPayOrder.expiration}
                            </p>
                        </div>
                        <Flex className={"wc py-3"}>
                            <Flex flex={"1 0 285px"} className={"py-3"}>
                                <ItemDetail icon={6} name={ "número de orden"} value={dataPayOrder.order } className={"mb-3"}/>
                                <ItemDetail icon={7} name={ "Número de la línea"} value={dataPayOrder.phoneNumber }/>
                            </Flex>
                            <Flex flex={"1 0 285px"} className={"justify-content-sm-start"}>
                                <ProductSection
                                    producto={dataPayOrder.product}
                                    total={dataPayOrder.amount}
                                />
                            </Flex>
                        </Flex>
                        <Flex className="wc py-4" flex={"10 1 100%"}>
                            <Flex flex={"0 250px"} className={"p-2"}>
                                <Btn typeBtn={"line"} disabled={global.loading} onClick={ () => props.history.push("/")} >Regresar</Btn>
                            </Flex>
                            <Flex flex={"0 250px"} className={"p-2"}>
                                <Btn loading={global.loading} onClick={ () => launchCheckoutPayments()  }>Pagar</Btn>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            <div className="wc mx-auto text-center py-4">
                <MediosPagosFooter/>
            </div>
        </Container>
          }
        </Fragment>
    )
}

let globalState = state => ({
    detalle : state.DataDetail, 
    global: state.Globalstate 
});

let handleState = { Setglobal, SetDataDetail};

export default connect(globalState, handleState)( withRouter( React.memo(Detalle )));
