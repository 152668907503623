import React, { useEffect } from "react";
import { NewResponseContainer } from "./styles";
import headerImage from "../../img/headerImage.png";
import calendarIcon from "../../img/calendarIcon.png";
import locationIcon from "../../img/locationIcon.png";
import simCardIcon from "../../img/simCardIcon.png";
import blueLogo from "../../img/blueLogo.png";
import androidButton from "../../img/androidButton.png";
import appleButton from "../../img/appleButton.png";
import unionIcon from "../../img/unionIcon.png";
import logoEpayco from "../../img/epayco.png";
import infoIcon from "../../img/infoIcon.png";
import { convertirFecha, formatDate } from "./helpers";
import Flex from "./../UI/flex";
import { MinIcon } from "./../UI/icon";
import { colors } from "./../UI/colors";
import Popup from "./Popup";
import { setNewTagManager } from '../utils/index';

const NewResponse = ({ dataResponse, extra6 }) => {
    const extra10 = JSON.parse(dataResponse.data.x_extra10);
    let selectedColor = "";
    let transactionState = "";
    switch (dataResponse.data.x_transaction_state) {
        case "Rechazada":
            selectedColor = colors.rojo;
            transactionState = "¡Transacción rechazada!";
            break;
        case "Pendiente":
            selectedColor = colors.naranja;
            transactionState = "Transacción pendiente";
            break;
        case "Aceptada":
            selectedColor = colors.naranja;
            transactionState = "Transacción Aceptada";
            break;
        default:
            transactionState = dataResponse.data.x_transaction_state;
            break;
    }
    useEffect(() => {
        sendNewTag();
    }, [])
    const getEvent = () => {
        let event = "";
        switch (extra6) {
            case "Linea Nueva":
                event = "formulario_linea_nueva_prepago";
                break;

            case "Portabilidad":
                event = "formulario_portabilidad_prepago";
                break;
            default:
                break;
        }
        return event;
    }
    const sendNewTag = () => {
        const extra10 = JSON.parse(dataResponse.data.x_extra10);
        const event = getEvent();
        const dataLayer = {
            'event': event,
            'plan': extra10.datos,
            'valor': dataResponse.data.x_amount,
            'flujo': 'TYP'
        };
        setNewTagManager(dataLayer);
    }
    return (
        <NewResponseContainer>
            <div className="col-12">
                <picture>
                    <img className="header-image" src={headerImage} alt="HeaderImage" />
                </picture>
                <h2 className="head-tittle">¡Listo! estás a un paso de ser Movistar!</h2>
                <p className="status-info">
                    {dataResponse.data.x_extra6 === "Portabilidad"
                        ? "Tu solicitud está pendiente de validación con tu operador actual"
                        : "Próximamente recibirás tu SIM con todos sus beneficios Movistar Prepago"}
                </p>
            </div>
            <Flex className="col-11 col-lg-8 bar-info  align-items-center">
                <Flex className={"align-items-center info-container"}>
                    <img src={simCardIcon} alt="" />
                    <div>
                        <h4>{dataResponse.data.x_extra6 === "Portabilidad" ? "Línea a portar " : "Número de línea"}</h4>
                        <p>{extra10.numeroLinea}</p>
                    </div>
                </Flex>
                <Flex className={"align-items-center info-container"}>
                    <img src={locationIcon} alt="" />
                    <div>
                        <h4>Dirección de Entrega SIM</h4>
                        <p>{extra10.direccionEntrega}</p>
                    </div>
                </Flex>
                <Flex className={"align-items-center info-container"}>
                    <img src={calendarIcon} alt="" />
                    <div>
                        <h4>Fecha de Entrega</h4>
                        <p>{formatDate(extra10.fechaEntrega)}</p>
                    </div>
                </Flex>
            </Flex>
            <h2 className="title-buy-detail">Resumen de tu compra</h2>
            <Flex className={"detail-buy-container col-11 col-lg-8"}>
                <section className="description-buy">
                    <div className="row-table">
                        <span>Referencia ePayco:</span>
                        <p className="font-18">
                            {dataResponse.data.x_ref_payco}
                        </p>
                    </div>
                    <div className="row-table">
                        <span>Estado de la transacción:</span>
                        <p className="font-18" style={{ color: selectedColor }}>
                            {transactionState}
                        </p>
                    </div>
                    <div className="row-table">
                        <span>Servicio:</span>
                        <p>
                            <span>{extra10.tituloOferta}</span>
                            <br />
                            <strong>Datos:</strong> {extra10.datos} <br />{" "}
                            <strong>Bono bienvenida:</strong> {extra10.bonoBienvenida} <br />
                            <strong>Apps incluidas:</strong> <div dangerouslySetInnerHTML={{ __html: extra10.appsIncluidas }} /> <br />{" "}
                            {extra10.características}
                        </p>
                    </div>
                    <div className="row-table">
                        <span>Nombre:</span>
                        <p className="font-18">{extra10.nombreCliente}</p>
                    </div>
                    <div className="row-table">
                        <span>
                            Número de <br />
                            Radicado:
                        </span>
                        <p className="font-18">
                            {dataResponse.data.x_extra1}{" "}
                            {dataResponse.data.x_extra6 === "Portabilidad" && (
                                <p className="font-14">
                                    Revisa el estado de tu portabilidad <strong>pasadas 24 horas</strong> de realizada
                                    tu solicitud
                                    <u>
                                        <a href="http://190.13.96.92/minisitio/Consulta_Numero_Celular.aspx">
                                            {" "}
                                            aquí en Movistar Prepago
                                        </a>
                                    </u>
                                </p>
                            )}
                        </p>
                    </div>
                    <div className="row-table">
                        <span>Fecha de compra:</span>
                        <p className="font-18">{convertirFecha(dataResponse.data.x_fecha_transaccion)}</p>
                    </div>
                    <Flex className="activation-info">
                        <img src={infoIcon} alt="infoIcon" />
                        <p>Una vez actives tu SIM, podrás consultar tus beneficios en la App Mi Movistar</p>
                    </Flex>
                </section>
                <section className="detail-buy">
                    <div className="amount-total">
                        <span>TOTAL</span>
                        <p>$ {dataResponse.data.x_amount.toLocaleString("es-CO")}</p>
                    </div>
                    <Flex className="info-movistar">
                        <img src={blueLogo} alt="logo movistar" />
                        <div>
                            <h4>App Mi Movistar</h4>
                            <p>Consulta tu saldo, paga tu recibo y más. Todo al alcance de tus manos.</p>
                        </div>
                    </Flex>
                    <Flex className={"apps-buttons"}>
                        <button>
                            <a href="https://play.google.com/store/apps/details?id=movistar.android.app&hl=es_CO&gl=US&pli=1​">
                                <img src={androidButton} alt="" />
                            </a>
                        </button>
                        <button>
                            <a href="https://apps.apple.com/py/app/mi-movistar-colombia/id498265077​">
                                <img src={appleButton} alt="" />
                            </a>
                        </button>
                    </Flex>
                    <button className="button-goto-movistar">
                        <a href="https://www.movistar.com.co/">Volver a Movistar.com.co</a>
                    </button>
                    <button className="button-goto-movistar button-imprimir-movistar" onClick={() => window.print()}>
                        <Flex className={"wc py-2"}>
                            <Flex flex={"0 0 20px"}>
                                <MinIcon hg={"20px"} icon={0} />
                            </Flex>
                            <Flex>
                                <span className={"ml-2"} style={{ color: colors.azul }}>Imprime este comprobante</span>
                            </Flex>
                        </Flex>
                    </button>
                </section>
            </Flex>
            <div className="powerby-epayco">
                <img className="icon-union" src={unionIcon} alt="" />
                <p>Pagos procesados por</p>
                <img className="logo-epayco" src={logoEpayco} alt="logo epayco" />
            </div>
            {
                process.env.REACT_APP_POPUP === "true" && <Popup />
            }

        </NewResponseContainer>
    );
};

export default NewResponse;
